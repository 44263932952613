<template>
  <div class="relative flex h-screen w-full flex-col">
    <div class="flex flex-1 flex-col items-center justify-center">
      <div class="mb-4 flex items-center justify-center">
        <svg
          class="w-20"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          ></path>
        </svg>
      </div>
      <h1 class="text-gray-800 mb-4 text-center text-2xl font-bold">
        Feature Temporarily Unavailable
      </h1>
      <p class="text-gray-600 mb-6 text-center text-lg">
        We're sorry, but the YouTube to Tweet feature is currently not working due to an issue with
        YouTube. Our team is working on resolving this as quickly as possible.
      </p>
    </div>
  </div>
  <!-- <div v-if="!isUserConnectedToYouTubeWithChannel" class="2xl:px-40">
    <h1 class="mb-4 text-3xl font-bold text-ds-text-primary">YouTube to Tweet</h1>
    <p>
      To use this feature, you first need to connect your YouTube account.
      <span>
        <RouterLink
          :to="{ path: 'settings', hash: 'connection' }"
          class="inline-block cursor-pointer text-blues-40 hover:underline"
        >
          Click here to do that.
        </RouterLink>
      </span>
    </p>
  </div>
  <div v-else class="py-2 2xl:px-40">
    <h1 class="mb-4 text-3xl font-bold text-ds-text-primary">YouTube to Tweet</h1>
    <choose-video
      v-show="!selectedYouTubeVideo || isSelectAnotherVideo"
      :videos="videos"
      :type="videosLayoutType"
      @select-video="selectVideo"
    />

    <div v-show="selectedYouTubeVideo">
      <div class="flex items-start justify-between">
        <div v-if="selectedYouTubeVideo">
          <h3 class="truncate text-ellipsis mb-2 max-w-full text-lg font-bold text-ds-text-primary">
            {{ selectedYouTubeVideo.snippet.title }}
          </h3>
          <div class="text-ds-text-secondary">
            <span>{{ formatNumberOfViews(selectedYouTubeVideo) }} Views</span> .
            <span>{{ formatTimeAgo(selectedYouTubeVideo) }}</span>
          </div>
        </div>
        <new-button @click="isSelectAnotherVideo = true" type="secondary"
          >Select another video</new-button
        >
      </div>
      <div
        id="repurpose-iframe"
        class="align-center my-4 flex justify-center"
        :class="isEditorDisabled && 'pointer-events-none'"
      >
        <iframe
          ref="youtube-video-editor"
          :src="mediaEditorURL"
          style="height: 1080px; width: 1200px"
        ></iframe>
      </div>
    </div>

    <discard-changes-modal v-if="showDiscardModal">
      <div v-if="userAction === 'RESTART'">
        <h3 class="mb-3 text-2xl font-bold text-ds-text-primary">Discard changes?</h3>
        <p class="text-lg font-semi-medium text-ds-text-secondary-tertiary">
          Your unsaved changes for the current video will be lost unless you post this video.
        </p>
        <div class="mt-6 flex gap-x-3">
          <new-button type="secondary" @click="showDiscardModal = false">Cancel</new-button>
          <new-button @click="discardChanges()">Discard</new-button>
        </div>
      </div>
      <div v-if="userAction === 'EDIT'">
        <h3 class="mb-3 text-2xl font-bold text-ds-text-primary">Do you want to make changes?</h3>
        <p class="text-lg font-semi-medium text-ds-text-secondary-tertiary">
          Your rendered video won’t be saved if you make any edits now. Schedule it first to save
          it.
        </p>
        <div class="mt-6 flex gap-x-3">
          <new-button type="secondary" @click="$emit('close')">Cancel</new-button>
          <new-button>Edit Video</new-button>
        </div>
      </div>
    </discard-changes-modal>
  </div> -->
</template>

<script>
  // import controller from '@/controller';
  // import { mapGetters, mapState } from 'vuex';
  // import dao from '@/dao';
  // import { EventBus } from '@/event-bus';
  // import ChooseVideo from '../components/YoutubeToTweet/ChooseVideo.vue';
  // import DiscardChangesModal from '@/components/YoutubeToTweet/Modals/DiscardChangesModal.vue';
  // import moment from 'moment';
  // import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  // import YoutubeMixin from '@/views/Mixins/YouTubeMixin.vue';
  // const config = require('@/config');
  // import lodash from 'lodash';

  export default {
    name: 'YoutubeToVideo',
    // data() {
    //   return {
    //     videos: [],
    //     youtubeShortDoc: null,
    //     hoveredIndex: null,
    //     selectedYouTubeVideo: null,
    //     isSelectAnotherVideo: false,
    //     videosLayoutType: 'full',
    //     showDiscardModal: false,
    //     userAction: 'RESTART',
    //     isEditorDisabled: false,
    //     isVideoRendered: false,
    //     isVideoRendering: false,
    //     isLoadingVideoInEditor: false,
    //   };
    // },
    // components: {
    //   ChooseVideo,
    //   DiscardChangesModal,
    // },
    // mixins: [SwalModalMixin, YoutubeMixin],
    // watch: {
    //   isSelectAnotherVideo(val) {
    //     if (val) {
    //       this.videosLayoutType = 'mini';
    //     } else {
    //       this.videosLayoutType = 'full';
    //     }
    //   },
    // },
    // async created() {
    //   if (this.isUserConnectedToYouTubeWithChannel) {
    //     await this.loadYouTubeData();
    //     const self = this;
    //     window.onmessage = async function (e) {
    //       const { data } = e;
    //       if (data.message === 'set-youtube-short-id') {
    //         const { youtubeShortId } = e.data;
    //         self.youtubeShortDoc = await dao.youtubeShorts.getShort(youtubeShortId);
    //       } else if (data.message === 'video-loaded') {
    //         self.isEditorDisabled = false;
    //         self.isLoadingVideoInEditor = false;
    //       } else if (data.message === 'video-loading') {
    //         self.isEditorDisabled = true;
    //         self.isLoadingVideoInEditor = true;
    //       }
    //     };
    //   }
    // },
    // mounted() {
    //   window.addEventListener('message', this.scheduleVideo);
    // },
    // computed: {
    //   ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    //   ...mapState(['schedule']),
    //   mediaEditorURL() {
    //     return config.mediaEditorURL;
    //   },
    //   videosLeft() {
    //     return 30 - this.userProfile.reels.renderCount;
    //   },
    // },
    // methods: {
    //   mouseOverMethod(video) {
    //     video.showRepurposeOverlay = true;
    //   },
    //   sendCanRenderVideoMessage() {
    //     const youtubeEditor = this.$refs['youtube-video-editor'];
    //     youtubeEditor.contentWindow.postMessage(
    //       {
    //         message: 'update-editor',
    //         data: {
    //           videosLeft: this.videosLeft,
    //         },
    //       },
    //       this.mediaEditorURL,
    //     );
    //   },
    //   setYoutubeURLInEditor(url) {
    //     const youtubeEditor = this.$refs['youtube-video-editor'];
    //     if (!youtubeEditor) return;
    //     const themeClass = lodash.find(document.body.classList, (className) =>
    //       className.startsWith('mode--'),
    //     );
    //     youtubeEditor.contentWindow.postMessage(
    //       {
    //         message: 'set-theme',
    //         data: {
    //           theme: themeClass,
    //         },
    //       },
    //       this.mediaEditorURL,
    //     );
    //     youtubeEditor.contentWindow.postMessage(
    //       {
    //         message: 'set-video-url',
    //         data: {
    //           videoURL: url,
    //         },
    //       },
    //       this.mediaEditorURL,
    //     );
    //   },
    //   discardChanges() {
    //     this.youtubeShortDoc = null;
    //     this.repurposeVideo(this.nextVideo);
    //     this.nextVideo = null;
    //     this.showDiscardModal = false;
    //     this.isSelectAnotherVideo = false;
    //     this.isLoadingVideoInEditor = false;
    //   },
    //   selectVideo(video) {
    //     if (this.userProfile.reels.renderCount >= 30) {
    //       this.swalModal({
    //         title: 'Sorry',
    //         text: 'You have reached the limit of 30 rendered videos.',
    //         type: 'warning',
    //       });
    //       return;
    //     }
    //     if (this.selectedYouTubeVideo) {
    //       this.showDiscardModal = true;
    //       this.nextVideo = video;
    //     } else {
    //       this.repurposeVideo(video);
    //     }
    //   },
    //   async repurposeVideo(video) {
    //     this.setYoutubeURLInEditor(`https://www.youtube.com/watch?v=${video.id}`);
    //     this.sendCanRenderVideoMessage();
    //     this.selectedYouTubeVideo = video;
    //     const videoDoc = await dao.youtubeVideos.getVideo(video.id);
    //     if (!videoDoc.exists) {
    //       dao.youtubeVideos.saveVideo(this.userProfile.uid, {
    //         videoId: video.id,
    //         channelId: video.snippet.channelId,
    //         title: video.snippet.title,
    //       });
    //     }
    //   },
    //   async loadYouTubeData() {
    //     try {
    //       const ownedVideos = await controller.youtube.getUserVideos(
    //         this.currentUser,
    //         this.userProfile,
    //       );
    //       const videoIds = ownedVideos.map((v) => v.id.videoId);

    //       this.videos = await controller.youtube.getVideosDetails(
    //         this.currentUser,
    //         this.userProfile,
    //         videoIds,
    //       );
    //     } catch (error) {
    //       console.error('Failed to load YouTube data:', error);
    //     }
    //   },
    //   createYoutubeShortPost() {
    //     EventBus.$emit('open-new-composer', {
    //       youtubeShortRef: this.youtubeShortDoc,
    //     });
    //   },
    //   createYoutubeShortInstagramPost() {
    //     const instagramSlot = this.schedule
    //       .getAllSlots()
    //       .find((slot) => slot.slotType === 'instagram');
    //     EventBus.$emit('open-new-composer', {
    //       youtubeShortRef: this.youtubeShortDoc,
    //       time: instagramSlot.time,
    //       post: null,
    //       source: {
    //         name: 'ShortEditor',
    //       },
    //       postType: 'instagram',
    //     });
    //   },
    //   formatNumberOfViews(video) {
    //     const numOfViews = Number(video.statistics.viewCount);
    //     return numOfViews.toLocaleString();
    //   },
    //   formatTimeAgo(video) {
    //     const publishedAt = moment(video.snippet.publishedAt);
    //     const now = moment();
    //     const duration = moment.duration(now.diff(publishedAt));
    //     const days = duration.asDays();

    //     if (days < 1) {
    //       return 'Today';
    //     } else if (days < 30) {
    //       const n = Math.floor(days);
    //       return `${n} day${n > 1 ? 's' : ''} ago`;
    //     } else if (days < 365) {
    //       const n = Math.floor(days / 30);
    //       return `${n} month${n > 1 ? 's' : ''} ago`;
    //     } else {
    //       const n = Math.floor(days / 365);
    //       return `${n} year${n > 1 ? 's' : ''} ago`;
    //     }
    //   },
    //   formatVideoTime(video) {
    //     const duration = moment.duration(video.contentDetails.duration);

    //     const formattedComponents = [duration.hours(), duration.minutes(), duration.seconds()].map(
    //       (component) => component.toString().padStart(2, '0'),
    //     );

    //     return formattedComponents.filter((part, index) => part !== '00' || index > 0).join(':');
    //   },
    //   scheduleVideo(e) {
    //     const { data } = e;
    //     if (data.message === 'schedule-on-x') {
    //       this.createYoutubeShortPost();
    //     }

    //     if (data.message === 'schedule-on-instagram') {
    //       this.createYoutubeShortInstagramPost();
    //     }
    //   },
    // },
  };
</script>

<style lang="scss" scoped>
  .video-card {
    position: relative;
  }
  .repurpose-overlay {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }
</style>
